@import "src/styles/global";

.albums-page {
  width: 100%;
  position: relative;

  &__title {
    color: #9d0000;
    font-size: 20px;
    margin-top: 24px;
    font-weight: bold;
  }

  &__albums-set-box {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
  }

  &__albums-box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  &__albumtitles {
    color: #9d0000;
    font-weight: bold;
    font-size: 20px;
    margin: 16px 0;
  }

  &__cover {
    box-sizing: border-box;
    width: 200px;
    height: 120px;
    object-fit: cover;
    justify-content: center;
    margin: 0 10px 24px;
    box-shadow: 3px 3px 2px #04070c;
  }
}