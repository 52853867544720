@import "~react-image-gallery/styles/scss/image-gallery.scss";
@import '~antd/dist/reset.css';
@import-normalize;

:root {
  touch-action: pan-x pan-y;
  height: 100%;
}

body {
  margin: 0;
  background-image: linear-gradient(90deg, white, #76c9f6);
  //background-color: #282c34;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizelegibility;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  margin: 10px auto;
}

.image-gallery-image {
  position: relative;
  width: 700px;
  max-width: 100%;
  height: 700px;
  max-height: calc(100vh - 200px);
  margin: 24px auto;
}

.image-gallery-thumbnail-image {
  width: 80px;
  height: 80px;
  object-fit: cover;
}

.ant-divider {
  border-color: #00314b70;
}

.ant-menu {
  background: none;
  color: #00314b;
  margin: 0 24px;
  font-size: calc(10px + 1vmin);
  font-weight: bold;
}

.ant-menu-submenu-popup {
  max-width: 200px;
  background: #61dafb;
  min-width: 100px;
}

.ant-menu-light.ant-menu-submenu > .ant-menu, .ant-menu-light > .ant-menu.ant-menu-submenu > .ant-menu {
  max-height: 300px;
}

.ant-menu-light.ant-menu-submenu>.ant-menu,
.ant-menu-light>.ant-menu.ant-menu-submenu>.ant-menu {
  max-width: 200px;
  background: #76c9f6;
  color: #00314b;
  font-size: calc(10px + 1vmin);
  font-weight: bold;
  min-width: 100px
}
.ant-menu::before {
  content: none;
}

.ant-menu::after {
  content: none;
}

.ant-menu-submenu-popup .ant-menu-vertical .ant-menu-submenu-title {
  padding-inline: 8px;
  padding-inline-end: 8px;
}

.ant-menu-horizontal {
  border: none;
}

.ant-menu-light .ant-menu-submenu-selected >.ant-menu-submenu-title, .ant-menu-light>.ant-menu .ant-menu-submenu-selected >.ant-menu-submenu-title {
  color: #1677ff;
}